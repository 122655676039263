<template>
  <v-app>
    <router-view id="content-wrap" />
  </v-app>
</template>

<script>
  export default {
    name: 'PagesIndexSolicitar',
  }
</script>
